/* ##remfixer: 1080 */
.Tips {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 8.666667rem;
  padding: 0.555556rem 0.611111rem;
  background-color: #fff;
}
.Tips .gift_imgs {
  width: 3.333333rem;
  height: 3.333333rem;
  margin: 0 auto 0.185185rem;
}
.Tips .gift_imgs img {
  width: 100%;
  height: 100%;
}
.Tips .gift_name {
  line-height: 0.740741rem;
  font-weight: 500;
  font-size: 0.555556rem;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 0.462963rem;
}
.Tips .gift_content {
  width: 100%;
  min-height: 1.444444rem;
  line-height: 0.481481rem;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
  text-align: left;
  margin-bottom: 0.462963rem;
}
.Tips .gift_content p {
  margin-bottom: 0.185185rem;
}
.Tips .gift_content p:last-child {
  margin: 0;
}
.Tips .cancelBtn {
  position: absolute;
  width: 0.833333rem;
  height: 0.833333rem;
  bottom: -1.388889rem;
  left: 50%;
  margin-left: -0.416667rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAMAAAAPdrEwAAAAe1BMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////NgkbwAAAAKHRSTlMA5ifw4C3zx6GFdlNAHxMPBfjZ0beRYFAz7ero1bStk41vZlw2HgsKZ/DSoQAAArhJREFUWMOslttyqkAQRbcBHC4Dg6KgSTTe7f//wtMtx4qVDKYR1qNSq6Z2X6qhoIyyNDZVuJ7N1mFl4jSLSozAKl1M6RfTRboa6F2+Uyfvy5ftLv2klnCzO9goL8qyyCN72G1CavlMHfpTJAEJk7g+4xfnOp6QECQF+nFJprdIY4tObNx+k1zQgywkZl5f8ZRrPScmzKAlXxBTZapHVMQscqhoJOSPI5QcPyTyRjMfS2K+HNS4L2KWJf6gMBLeCb04SWlM8UfMUpetQ0/cVqr+NPBImnWPF9jLCERPzGuiWYOXaGZE6053zm8OLF7EBvzujkyKOZsjvEzE7rm3lqXhNCwGYDkT4+tB6ecGg2ikv/0/7zGQve95OQe1xWC2XK6fpVzwDDoMxoW8q34sMCI6YQROLMrwwCXkjYRR4F0VXvBNwlvUYRQc79jkYVimREeMxJFoWjw+usJoVA/PdoFEPxoZN+A93ZRnHz6SwLyhkzcTJPDBuyhFC18ytdcsO/it0yy73euu+fbBjRXnfoWHgLrdYmYCeLhyV6zueymGD0PdbjYLBj7i+5bii9F2P0zcvf5iLN+a//OYAAq31sxM2kRSyUPj1pslkbTdeTU0br0Z9W3/lVzOMzRuvRlnbroSEW8qQOHWmxnepJGM5QY6t96MDa8OqeIOOrfejB3XUYp5gNKtNuMgbWdkYLRurRlWBrWSwLVurVmao5Ja5tC6tWbk0nd8nBbQufVmFHy2gu+0Ejq33oyS78d/zdnJDQAwCAPB/rumBRAa2Q3kkQt7Fy4NNwQeI7x88MnAhw6/J/ipwlEAB5gbuzAswIjjghmMkzAEu+gOCwesSa7cwUrqijSs/w5aONQCAZHDWg7GQYTowKfDtQ4yOzTugL7TEE6eMOXjRFVer52lYIvK3AvYNm38ld1Q0Q+VWqnJHcBVNgAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100% 100%;
  text-indent: -1.851852rem;
  overflow: hidden;
}
.Tips .confirmBtn {
  display: none;
}
.Tips .appInstall {
  width: 6.666667rem;
  height: 1.055556rem;
  margin: 0 auto;
}
.Tips .appInstall .AppInstallBtn {
  width: 6.666667rem;
  height: 1.055556rem;
}
.Tips .appInstall .AppInstallBtn-btn {
  width: 13.333333rem;
  height: 2.111111rem;
  line-height: 2.111111rem;
  font-size: 0.888889rem;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

/*# sourceMappingURL=tips.db8bf520.css.map*/