/* ##remfixer: 1080 */

.Tips {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 8.666667rem;
  padding: 0.555556rem 0.611111rem;
  background-color: #fff;
}

.Tips .gift_imgs {
  width: 3.333333rem;
  height: 3.333333rem;
  margin: 0 auto 0.185185rem;
}

.Tips .gift_imgs img {
  width: 100%;
  height: 100%;
}

.Tips .gift_name {
  line-height: 0.740741rem;
  font-weight: 500;
  font-size: 0.555556rem;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 0.462963rem;
}

.Tips .gift_content {
  width: 100%;
  min-height: 1.444444rem;
  line-height: 0.481481rem;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
  text-align: left;
  margin-bottom: 0.462963rem;
}

.Tips .gift_content p {
  margin-bottom: 0.185185rem;
}

.Tips .gift_content p:last-child {
  margin: 0;
}

.Tips .cancelBtn {
  position: absolute;
  width: 0.833333rem;
  height: 0.833333rem;
  bottom: -1.388889rem;
  left: 50%;
  margin-left: -0.416667rem;
  background: url(~assets/images/close.png) no-repeat;
  background-size: 100% 100%;
  text-indent: -1.851852rem;
  overflow: hidden;
}

.Tips .confirmBtn {
  display: none;
}

.Tips .appInstall {
  width: 6.666667rem;
  height: 1.055556rem;
  margin: 0 auto;
}

.Tips .appInstall .AppInstallBtn {
  width: 6.666667rem;
  height: 1.055556rem;
}

.Tips .appInstall .AppInstallBtn-btn {
  width: 13.333333rem;
  height: 2.111111rem;
  line-height: 2.111111rem;
  font-size: 0.888889rem;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}